import React from "react";
import "./HomeMobile.css";
import { useEffect } from "react";

import gsap from "gsap";

import ScrollTrigger from "gsap/ScrollTrigger";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
gsap.registerPlugin(ScrollTrigger);

const HomeMobile = () => {
  useEffect(() => {
    gsap.set(".one h1", { opacity: 0, y: 150, duration: 1 });

    gsap.set(".one .contentMobile", { opacity: 0, y: 150, duration: 1 });
    gsap.to(".one h1 ", {
      opacity: 1,
      y: 0,
      duration: 1,
      scrollTrigger: {
        trigger: ".one h1",
        start: "top 80%",
        end: "bottom 80%",
        toggleActions: "play none none reverse",
      },
    });

    gsap.to(".one .contentMobile ", {
      opacity: 1,
      y: 0,
      duration: 1,
      scrollTrigger: {
        trigger: ".one .contentMobile",
        start: "top 80%",
        end: "bottom 80%",
        toggleActions: "play none none reverse",
      },
    });
  }, []);

  return (
    <div className="mobileHome">
      <Swiper
        direction={"vertical"}
        pagination={{
          clickable: true,
        }}
        mousewheel={true}
        className="mySwiper"
        allowTouchMove={true}
      >
        <SwiperSlide>
          {" "}
          <div className="section one">
            <h1>Përkrahim ndryshimin dhe rritjen!</h1>
            <div className="contentMobile">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <circle cx="9" cy="9" r="9" fill="#FBE54D" />
              </svg>
              <div className="line"></div>
              <p>
                Jemi të entuziazmuar që je këtu dhe mezi presim t’i zbulojmë më
                të rejat. Tani për tani jemi duke punuar në diçka që ia vlen,
                prandaj na përcill!
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div className="section two">
            <h1>Investo në të ardhmen tonë!</h1>
            <div className="contentMobile">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <circle cx="9" cy="9" r="9" fill="#FBE54D" />
              </svg>
              <div className="line"></div>
              <p>
                Bëhu pjesë e rrugëtimit drejt suksesit duke investuar në
                zhvillimin dhe ndryshimet e Kerkoj.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div className="section three">
            <h1>Na kontakto për çdo pyetje ose ndihmë</h1>
            <div className="contentMobile">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <circle cx="9" cy="9" r="9" fill="#FBE54D" />
              </svg>
              <div className="line"></div>
              <p className="contactFlex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                >
                  <path
                    d="M2.78891 5.12339C2.59231 4.53108 2.49401 4.23492 2.56556 4.05249C2.6277 3.89406 2.76068 3.77404 2.92463 3.72842C3.11341 3.67589 3.39797 3.80395 3.9671 4.06005L22.0775 12.2097C22.6347 12.4604 22.9133 12.5858 22.9994 12.7599C23.0742 12.9113 23.0742 13.0888 22.9994 13.2401C22.9133 13.4143 22.6347 13.5396 22.0775 13.7904L3.97341 21.9372C3.40256 22.1941 3.11714 22.3225 2.92817 22.2698C2.76406 22.224 2.63107 22.1036 2.56915 21.9449C2.49784 21.7621 2.5972 21.4653 2.79592 20.8717L5.32542 13.3158C5.35945 13.2142 5.37646 13.1633 5.38326 13.1113C5.38929 13.0652 5.38935 13.0185 5.38344 12.9723C5.37677 12.9203 5.35989 12.8694 5.32612 12.7677L2.78891 5.12339Z"
                    fill="#FBE54D"
                  />
                </svg>
                info@kerkoj.com
              </p>
              <p className="contactFlex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.21553 16.7849C17.0543 24.6236 19.3512 22.7477 20.3771 22.0149C20.5429 21.9208 24.3398 19.4742 21.9785 17.1133C16.5016 11.636 17.6129 18.7554 12.4279 13.5713C7.24402 8.38623 14.3639 9.49832 8.88713 4.02163C6.52554 1.66007 4.07869 5.45724 3.98563 5.62198C3.25179 6.64788 1.37675 8.94618 9.21553 16.7849Z"
                    fill="#FBE54D"
                  />
                </svg>
                +383 (0)49 387 285
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default HomeMobile;
