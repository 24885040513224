import React from "react";
import "./Investment.css";
import { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Investment = () => {
  let outroEnd = 500;
  useEffect(() => {
    if (window.innerWidth < 1600) {
      outroEnd = 1200;
    }
    gsap.set(".investment h1", { opacity: 0, x: 300, duration: 0.6 });
    gsap.set(".investment .contentWrapper", {
      opacity: 0,
      x: 300,
      duration: 0.6,
    });
    ScrollTrigger.create({
      trigger: ".outroWrapper",
      start: "top top",
      end: `+=${outroEnd}`, // This specifies the end point 500px after the start
      pin: true, // This pins the trigger element
      pinSpacing: true, // This can be set to true or false depending on whether you want to include spacing for the pinned element
    });
    gsap.to(".investment h1 ", {
      opacity: 1,
      x: 0,
      duration: 0.1,
      scrollTrigger: {
        trigger: ".investment h1",
        start: "top 90%",
        end: "bottom 80%",
        toggleActions: "play none none reverse",
      },
    });
    ScrollTrigger.create({
      trigger: ".investment",
      start: "top top",
      end: "+=500", // This specifies the end point 500px after the start
      pin: true, // This pins the trigger element
      pinSpacing: false, // This can be set to true or false depending on whether you want to include spacing for the pinned element
    });

    gsap.to(".investment .contentWrapper ", {
      opacity: 1,
      x: 0,
      duration: 0.6,
      scrollTrigger: {
        trigger: ".investment .contentWrapper",

        start: "top 90%",
        end: "bottom 80%",
        toggleActions: "play none none reverse",
      },
    });
  }, []);

  return (
    <div className="investment">
      <div className="contentWrapper">
        <h1>
          Investo në të <br /> ardhmen tonë!
        </h1>
        <div className="contentInvestment">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <circle cx="10" cy="10" r="10" fill="#FBE54D" />
          </svg>
          <div className="line"></div>
          <p>
            Bëhu pjesë e rrugëtimit drejt suksesit duke investuar në zhvillimin
            dhe ndryshimet e Kerkoj.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Investment;
