import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="Footer">
      <p className="">Eksploro. Zbulo. Zhvillo.</p>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.facebook.com/kerkojcom/"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M18.3333 9.99996C18.3333 5.39758 14.6023 1.66663 9.99996 1.66663C5.39758 1.66663 1.66663 5.39758 1.66663 9.99996C1.66663 14.1594 4.71401 17.6069 8.69788 18.2321V12.4088H6.58199V9.99996H8.69788V8.16402C8.69788 6.07548 9.94198 4.92183 11.8455 4.92183C12.7572 4.92183 13.7109 5.08459 13.7109 5.08459V7.13538H12.6601C11.6249 7.13538 11.302 7.77774 11.302 8.43676V9.99996H13.6132L13.2438 12.4088H11.302V18.2321C15.2859 17.6069 18.3333 14.1594 18.3333 9.99996Z"
            fill="white"
          />
        </svg>
      </a>
    </div>
  );
};

export default Footer;
