import React from "react";
import "./Hero.css";
const Hero = () => {
  return (
    <div className="Hero">
      <h1>
        Kerkoj po <br /> ndryshon!
      </h1>
      <div className="content">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <circle cx="10" cy="10" r="10" fill="#FBE54D" />
        </svg>
        <div className="line"></div>
        <p>
          Jemi të entuziazmuar që je këtu. Jemi duke punuar për të sjellë gjëra
          të reja, prandaj na përcill.
        </p>
      </div>
    </div>
  );
};

export default Hero;
