import React from "react";
import "./Outro.css";
const Outro = () => {
  return (
    <div className="outroWrapper">
      <h2>
        Faleminderit për <br /> kohën tënde
      </h2>
    </div>
  );
};

export default Outro;
