import React, { useEffect, useState } from "react";
import "./Header.css";

const Header: React.FC = () => {
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const updateScrollProgress = () => {
      const scrollTop = window.pageYOffset;
      const windowHeight = window.innerHeight;
      const docHeight = document.documentElement.scrollHeight;

      const totalDocScrollLength = docHeight - windowHeight;
      const scrollPosition = (scrollTop / totalDocScrollLength) * 100;

      setScrollProgress(scrollPosition);
    };

    const throttle = (func: any, limit: any) => {
      let inThrottle: any;
      return (...args: any) => {
        if (!inThrottle) {
          func.apply(this, args);
          inThrottle = true;
          setTimeout(() => (inThrottle = false), limit);
        }
      };
    };

    const throttledScroll = throttle(updateScrollProgress, 10);

    window.addEventListener("scroll", throttledScroll);

    return () => window.removeEventListener("scroll", throttledScroll);
  }, []);

  return (
    <div className="header">
      <div className="lineWrapper">
        <div
          className="beginnerLine"
          style={{
            width: `${scrollProgress}%`,
          }}
        ></div>
      </div>
      <img src="../../kerkojLogo.svg" alt="" />
      <p className="desktop">Që nga 2018</p>

    </div>
  );
};

export default Header;
