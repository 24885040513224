import React, { useRef, useEffect, useState } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "./Home.css";
import Hero from "../Hero";
import Investment from "../Investment";
import Contact from "../Contact";
import Outro from "../Outro";

gsap.registerPlugin(ScrollTrigger);

const Home: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [imageIndex, setImageIndex] = useState(0);
  const totalImages = 671;
  const [sectionMargin ,setSectionMargin] = useState('600px');
  const imagesToShow = new Array(totalImages)
    .fill(null)
    .map((_, index) => index);

  useEffect(() => {
    if(window.innerWidth < 1600){
      setSectionMargin('950px');
    }
    ScrollTrigger.refresh();
    const updateImageIndexBasedOnScroll = () => {
      if (!containerRef.current) return;

      ScrollTrigger.create({
        trigger: containerRef.current,
        start: "top top",
        end: `+=${totalImages * 10}`,
        pin: true,
        pinSpacing: false,
        onUpdate: (self) => {
          const progress = self.progress + 0.15;
          const index = Math.min(
            totalImages - 1,
            Math.floor(progress * totalImages)
          );
          setImageIndex(index);
        },
      });
    };

    updateImageIndexBasedOnScroll();

    gsap.set(".Hero h1", { opacity: 0, x: -300, duration: 0.6 });
    gsap.set(".Hero .content", { opacity: 0, x: 300, duration: 0.6 });
    gsap.set(".outroWrapper h2", { opacity: 0, y: 300, duration: 0.6 });

    gsap.to(".outroWrapper h2 ", {
      opacity: 1,
      y: 0,
      duration: 0.6,
      scrollTrigger: {
        trigger: ".outroWrapper h2",
        start: "top 50%",
        end: "bottom 80%",
        toggleActions: "play none none reverse",
      },
    });
    gsap.to(".Hero h1 ", {
      opacity: 1,
      x: 0,
      duration: 0.6,
      scrollTrigger: {
        trigger: ".Hero h1",
        start: "top 50%",
        end: "bottom 80%",
        toggleActions: "play none none reverse",
      },
    });
    gsap.to(".Hero .content ", {
      opacity: 1,
      x: 0,
      duration: 0.6,
      scrollTrigger: {
        trigger: ".Hero .content",

        start: "top 70%",
        end: "bottom 80%",
        toggleActions: "play none none reverse",
      },
    });

    return () => ScrollTrigger.killAll();
  }, []);

  const getImagePath = (index: number) => {
    const imageName = `Comp 3_${String(index).padStart(5, "0")}_result.avif`;
    return `../../assets/${imageName}`;
  };

  return (
    <div>
      <div className="homeWrapper" ref={containerRef}>
        {imagesToShow.map((index) => (
          <img
            key={index}
            className={`animationImages ${
              index === imageIndex ? "visible" : "hidden"
            }`}
            src={getImagePath(index)}
            alt={`Scroll Animation Frame ${index}`}
            style={{ display: index === imageIndex ? "block" : "none" }}
          />
        ))}
      </div>
      <Hero />
      <div style={{ marginTop: "0px" }}>
        <Investment />
      </div>
      <div style={{ marginTop: sectionMargin }}>
        <Contact />
      </div>
      <div style={{ marginTop: "600px" }}>
        <Outro />
      </div>
    </div>
  );
};

export default Home;
