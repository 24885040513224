import React from "react";
import "./App.css";
import Home from "./components/Home";
import { ReactLenis, useLenis } from "@studio-freight/react-lenis";
import { useEffect, useState } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeMobile from "./components/HomeMobile";

function App() {
  const lenis: any = useLenis();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  useEffect(() => {
    if (lenis) {
      const updateScrollTrigger = () => {
        ScrollTrigger.update();
      };

      lenis.on("scroll", updateScrollTrigger);

      return () => {
        if (lenis) {
          lenis.off("scroll", updateScrollTrigger);
        }
        ScrollTrigger.getAll().forEach((instance) => instance.kill());
      };
    }
    if (window.innerWidth < 1200) {
      setIsMobile(true);
    }
  }, [lenis]);
  return (
    <ReactLenis
      options={{
        lerp: 0.06,
        // easing: (t: number) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      }}
      root
    >
      <div className="appWrapper">
        <Header />
        {isMobile ? (
          <div className="mobile">
            <HomeMobile />
          </div>
        ) : (
          <div className="desktop">
            <Home />
          </div>
        )}

        <Footer />
      </div>
    </ReactLenis>
  );
}

export default App;
